<div class="o-wrapper">
  <main id="content">
    <div [formGroup]="form" *ngIf="form">
      <div class="c-head-section c-head-section--tab c-head-section bg-cream">
        <!--parte superior-->
        <div class="c-head-section--tab__top">
          <div class="c-head-section__left">
            <div class="c-head-section--tab__ico is-active" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M3.333,5.462a2.384,2.384,0,0,1-1.768-.8L.278,3.412A.97.97,0,0,1,.211,2.126a.786.786,0,0,1,1.177-.074L2.709,3.339a.806.806,0,0,0,.6.3.8.8,0,0,0,.614-.268l3-3.122A.784.784,0,0,1,8.083.306a.971.971,0,0,1-.009,1.265L5.083,4.678A2.368,2.368,0,0,1,3.333,5.462ZM20,3.642a.874.874,0,0,0-.833-.91H10.833a.874.874,0,0,0-.833.91.874.874,0,0,0,.833.91h8.333A.874.874,0,0,0,20,3.642ZM5.083,11.958,8.074,8.851a.962.962,0,0,0,.258-.884A.88.88,0,0,0,7.746,7.3a.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-1.178,0L1.422,9.213a.784.784,0,0,0-1.178,0,.97.97,0,0,0,0,1.287l1.321,1.442a2.353,2.353,0,0,0,3.522.015ZM20,10.923a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,10.923ZM5.083,19.239,8.07,16.132a.962.962,0,0,0,.258-.884.88.88,0,0,0-.585-.672.786.786,0,0,0-.821.238l-3,3.122a.8.8,0,0,1-.614.268.806.806,0,0,1-.6-.3L1.388,16.613a.786.786,0,0,0-1.177.074.97.97,0,0,0,.067,1.286l1.287,1.25a2.353,2.353,0,0,0,3.522.015ZM20,18.2a.874.874,0,0,0-.833-.91H10.833a.914.914,0,0,0,0,1.82h8.333A.874.874,0,0,0,20,18.2Z"
                  transform="translate(.0001-.023)" />
              </svg>
            </div>
            <div class="c-head-section--tab__info">
              <h2 class="c-head-section--tab__title">{{questionary?questionary.code:''}}</h2>
              <div class="c-head-section--tab-data">
                <div class="c-head-section--tab-data__item">
                  <div class="c-head-section--tab-data__ico color" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path
                        d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,1,0,1.333,0V15a3.667,3.667,0,1,1,7.333,0v.333a.667.667,0,1,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z" />
                    </svg>
                  </div>
                  <div>
                    {{questionary? (questionary.thirdPartyType | translatedescription):''}}
                  </div>
                </div>
                <div class="c-head-section--tab-data__item">
                  <div class="c-head-section--tab-data__ico color" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path
                        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm6.667,8a6.625,6.625,0,0,1-1.108,3.672l-.795-.8a.333.333,0,0,1-.1-.235V10a2,2,0,0,0-2-2h-2A.667.667,0,0,1,8,7.333V7a.333.333,0,0,1,.333-.333A1.667,1.667,0,0,0,10,5V4.333A.333.333,0,0,1,10.333,4h.919a1.677,1.677,0,0,0,1.178-.488l.251-.251A6.646,6.646,0,0,1,14.667,8Zm-13.3.642,2.105,2.105a1.985,1.985,0,0,0,1.415.586H6.667A.667.667,0,0,1,7.333,12v2.633A6.677,6.677,0,0,1,1.366,8.642Zm7.3,5.991V12a2,2,0,0,0-2-2H4.886a.667.667,0,0,1-.472-.2L1.442,6.833a6.652,6.652,0,0,1,10.2-4.413l-.149.149a.338.338,0,0,1-.235.1h-.919A1.667,1.667,0,0,0,8.667,4.333V5a.333.333,0,0,1-.333.333A1.667,1.667,0,0,0,6.667,7v.333a2,2,0,0,0,2,2h2a.667.667,0,0,1,.667.667v.641a1.677,1.677,0,0,0,.488,1.178l.891.891A6.647,6.647,0,0,1,8.667,14.633Z" />
                    </svg>
                  </div>
                  <div>
                    {{questionary? (questionary.country | translatedescription):''}}
                  </div>
                </div>
                <div class="c-head-section--tab-data__item">
                  <div class="c-head-section--tab-data__ico color" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path
                        d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z" />
                    </svg>
                  </div>
                  <div>
                    {{questionary? (questionary.sector | translatedescription):''}}
                  </div>
                </div>
                <div class="c-head-section--tab-data__item">
                  <div class="c-head-section--tab-data__ico color" aria-hidden="true">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16.002">
                      <path
                        d="M15.482,3.829,13.474,1.117A2.67,2.67,0,0,0,11.3,0H4.7A2.667,2.667,0,0,0,2.533,1.1L.49,3.836a2.662,2.662,0,0,0,.082,3.19l5.862,8.22A1.99,1.99,0,0,0,8,16H8a2,2,0,0,0,1.586-.783L15.4,7.09a2.667,2.667,0,0,0,.08-3.261ZM12.4,1.9,14.408,4.62c.011.015.013.032.024.047H11.17l-.952-3.334H11.3A1.341,1.341,0,0,1,12.4,1.9ZM8,12.747,6.2,6H9.8ZM6.218,4.667,7.17,1.333H8.831l.952,3.334ZM3.611,1.892A1.338,1.338,0,0,1,4.7,1.333H5.783L4.831,4.667H1.545c.009-.015.012-.033.023-.047ZM1.632,6.217A1.267,1.267,0,0,1,1.509,6H4.822l2,7.488Zm7.55,7.278,2-7.494h3.324a1.4,1.4,0,0,1-.162.281Z"
                        transform="translate(.0001)" />
                    </svg>
                  </div>
                  <div>
                    &#36;{{questionary?questionary.amount:'-'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-head-section__right">
            <ng-container *appAuthRole="['Owner','Manager']">
              <button [disabled]="!form.valid || !form.dirty || isLoading" *ngIf="form && !questionary?.isMaster"
                type="button" class="c-button" (click)="buildOutput()">{{'ListadoPreguntas.button.save.valor' |
                translate }}</button>
            </ng-container>
          </div>
        </div>
        <!--volver a listado y duplicar-->
        <div class="c-head-section__actions">
          <div class="c-go-back">
            <a [routerLink]="['/plantillas']" class="c-go-back__button">
              <span class="c-go-back__arrow" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.999 12">
                  <path
                    d="M10.88 17.715a1 1 0 0 0 0 -1.415L7.588 13.01 18 13a1 1 0 0 0 0 -2l-10.414.007L10.88 7.715A1 1 0 0 0 9.466 6.3L5.88 9.886a3 3 0 0 0 0 4.243l3.586 3.586a1 1 0 0 0 1.414 0"
                    transform="translate(-5-6.01)" />
                </svg>
              </span> {{'ListadoPreguntas.back.valor' | translate }}
            </a>
          </div>
          <div class="c-go-back c-go-back--dark">
            <button [disabled]="isLoading" type="button" (click)="duplicarPlantilla()" class="c-go-back__button">
              <span class="c-go-back__arrow c-go-back__arrow--large" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20">
                  <defs>
                    <style>
                      .a {
                        fill: url(#a);
                      }

                      .b {
                        fill: none;
                      }
                    </style>
                    <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#fdc130" />
                      <stop offset="1" stop-color="#faa868" />
                    </linearGradient>
                  </defs>
                  <path class="a"
                    d="M11.167,16.667A4.172,4.172,0,0,0,15.333,12.5V5.2a3.31,3.31,0,0,0-.977-2.357L12.488.977A3.31,3.31,0,0,0,10.131,0H6.167A4.172,4.172,0,0,0,2,4.167V12.5a4.172,4.172,0,0,0,4.167,4.167ZM3.667,12.5V4.167a2.5,2.5,0,0,1,2.5-2.5s4.1.012,4.167.02V3.333A1.667,1.667,0,0,0,12,5h1.647c.008.068.02,7.5.02,7.5a2.5,2.5,0,0,1-2.5,2.5h-5a2.5,2.5,0,0,1-2.5-2.5Zm15-5.833v9.167A4.172,4.172,0,0,1,14.5,20H7a.833.833,0,1,1,0-1.667h7.5a2.5,2.5,0,0,0,2.5-2.5V6.667a.833.833,0,0,1,1.667,0Z"
                    transform="translate(0 0)" />
                  <rect />
                </svg>
              </span>
              {{'ListadoPreguntas.duplicate.valor' | translate }}
            </button>
          </div>
        </div>
        <!--parte inferior-->
        <div class="c-head-section--tab__bottom">
          <div *ngIf="isLoading">
            <mat-progress-bar mode="indeterminate" class="c-spinner"></mat-progress-bar>
          </div>
          <!--tabs-->
          <mat-tab-group *ngIf="!isLoading" class="c-tabs">
            <!--configuración básica-->
            <mat-tab label="{{'ListadoPreguntas.tab.conf.valor' | translate }}">
              <div class="o-scroll o-scroll--small">
                <app-questionary-configuration *ngIf="form" [questionary]="questionary" [canEdit]="canEdit"
                  (openDeleteDialog)="openDeleteDialog($event)"></app-questionary-configuration>
              </div>
            </mat-tab>
            <mat-tab label="{{'ListadoPreguntas.tab.questions.valor' | translate }}">
              <div class="o-scroll">
                <app-questionary-blocks *ngIf="form" [questionary]="questionary" [canEdit]="canEdit">
                </app-questionary-blocks>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <span [ngClass]="{'invalid-tab': form.errors?.incorrectWeights}">
                  {{'ListadoPreguntas.tab.weights' | translate }}
                </span>
              </ng-template>
              <div class="o-scroll">
                <app-questionary-weights [questionary]="questionary" [canEdit]="canEdit"></app-questionary-weights>
              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </div>
    </div>
  </main>
</div>
